import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { fetchLatestNoticeApi, fetchNoticeListApi } from '@/apis/notice.api';
import { INTERACTION_TYPE_CODE } from '@/constants/common.const';
import { Confirmation, LocationCode } from '@/enums/common.enum';
import type { Pagination } from '@/types/common/pagination.type';
import type { NoticeModel } from '@/types/notice/notice-model.type';
import type { NoticeRequest } from '@/types/notice/notice-request.type';
import type { LatestNoticeResponse } from '@/types/notice/notice-response.type';
import { getConfigs } from '@/utils/common.util';

export const useNoticeStore = defineStore('noticeStore', () => {
  const { INDIE_CHANNEL_SEQ } = getConfigs();

  const defaultParams = {
    headlineTitleYn: Confirmation.YES,
    interactionTypeCode: `${INTERACTION_TYPE_CODE.LIKE},${INTERACTION_TYPE_CODE.DISLIKE},${INTERACTION_TYPE_CODE.COMMENT},${INTERACTION_TYPE_CODE.VIEW}`,
    translationYn: Confirmation.YES,
    locationCode: LocationCode.ALL
  };

  const noticeList = ref<Pagination<NoticeModel[]>>();

  const pinnedNotices = computed(
    () => noticeList.value?.contents.filter((notice: NoticeModel) => notice.isFixed) || []
  );

  const latestNotice = ref<LatestNoticeResponse>();

  const fetchNoticeList = async (params: NoticeRequest) => {
    const res = await fetchNoticeListApi(INDIE_CHANNEL_SEQ, { ...defaultParams, ...params });
    if (res) {
      noticeList.value = res;
    }
  };

  const fetchLatestNotice = async () => {
    const res = await fetchLatestNoticeApi(INDIE_CHANNEL_SEQ, defaultParams);
    if (res) {
      latestNotice.value = res;
    }
  };

  return {
    noticeList,
    pinnedNotices,
    latestNotice,
    fetchNoticeList,
    fetchLatestNotice
  };
});
